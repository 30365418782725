<template>
  <div class="AlreadyHaveAccount d-flex justify-content-end align-items-center full-width pt-4 pr-4">
    <div class="pr-3 emobg-color-ink">
      {{ $t('refactor.user_signup.general.already_account') }}
    </div>
    <ui-button
      v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
      class="emobg-font-weight-bold"
      data-test-id="signup-user-top-login-button"
      @clickbutton="goLogin"
    >
      {{ $t('refactor.user_signup.general.login') }}
    </ui-button>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router/composables';
import { useTheme } from '@/composable/Theme/useTheme';

const router = useRouter();

const { fetchButtonSpecs } = useTheme();

defineExpose({
  fetchButtonSpecs,
});

function goLogin() {
  window.location.href = router.resolve('/').href;
}
</script>
<style lang="scss">
  .AlreadyHaveAccount {
    position: absolute;
    top: 0;

    @media (max-width: 992px) {
      position: relative;
      justify-content: center;
      width: 100%;
      padding: 0;
    }
  }
</style>
