import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import values from 'lodash/values';
import size from 'lodash/size';
import map from 'lodash/map';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import sortedUniq from 'lodash/sortedUniq';
import { compactArray, isArrayValue } from '@emobg/web-utils';

/**
 * Group documents by "group" property and sort them in the following
 * other: parent documents group, operator documents group and the rest of the groups
 * @param {array} documents
 * @return {array}
 */
export const groupItemsBy = documents => {
  const legalDocuments = isArrayValue(documents) ? documents : [];

  const groupDocuments = groupBy(legalDocuments, 'group');

  const {
    parent_documents: parentDocuments = [],
    operator_documents: operatorDocuments = [],
  } = groupDocuments;

  const specialDocuments = omit(groupDocuments, ['parent_documents', 'operator_documents']);
  const others = values(specialDocuments);

  const groups = [
    size(parentDocuments) ? parentDocuments : null,
    size(operatorDocuments) ? operatorDocuments : null,
    ...others,
  ];

  return compactArray(groups);
};

/**
 * Returns true when the document's uuids are in accepted uuids array
 * @param {string[]} acceptedUuids
 * @param {array} group
 * @return {boolean}
 */
export const isAccepted = (acceptedUuids = [], group) => {
  if (!acceptedUuids.length) {
    return false;
  }

  const groupedDocuments = isArrayValue(group) ? group : [];

  const uuids = compactArray(map(groupedDocuments, 'uuid'));

  const itemsIntersection = intersection(acceptedUuids, uuids);

  if (!size(itemsIntersection)) {
    return false;
  }

  return isEqual(sortedUniq(itemsIntersection), sortedUniq(uuids));
};
