var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SignUpTemplate',{staticClass:"UserView",attrs:{"left-image":_vm.userSignUpImage}},[(_vm.isOnFirstStep)?_c('AlreadyHaveAccount'):_vm._e(),_c('div',{class:[
      'UserView__content--aligned h-100',
      {
        'UserView__content--step_1 p-0 w-100': _vm.isOnFirstStep || _vm.isOnInvitationFirstStep,
        'UserView__content--step_2 py-0': _vm.isOnSecondStep,
        'UserView__content--step_3 pt-6': _vm.isOnThirdStep || _vm.isOnInvitationThirdStep,
      },
    ]},[_c('ul',{staticClass:"navigator pb-4 w-100",class:{'UserView__navigator--hidden': _vm.getStepNumber <= 1}},[_c('div',{staticClass:"navigator__wrapper"},[_c('SignUpBullet',{staticClass:"mr-3",attrs:{"to":{ path: './step1' },"done":_vm.getStepNumber,"number":1,"is-clickable":_vm.isActive(1),"data-test-id":"signup-user-top-bullet-step1"}}),(!_vm.isEmployee)?_c('SignUpBullet',{staticClass:"mr-3",attrs:{"to":{ path: './step2' },"done":_vm.getStepNumber,"number":2,"is-clickable":_vm.isActive(2),"data-test-id":"signup-user-top-bullet-step2"}}):_vm._e(),_c('SignUpBullet',{attrs:{"to":{ path: './step3' },"done":_vm.getStepNumber,"number":_vm.isEmployee ? 2 : 3,"is-clickable":_vm.isActive(_vm.isEmployee ? 2 : 3),"data-test-id":"signup-user-top-bullet-step3"}})],1)]),_c('RouterView',{staticClass:"content",attrs:{"auth":_vm.auth}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }