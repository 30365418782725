<template>
  <RouterView :auth="auth" />
</template>
<script>
import { mapMutations } from 'vuex';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { USER_MODULE } from '../constants/modules';

export default {
  name: 'B2BView',
  props: {
    auth: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { notifyError } = useNotifications();
    return { notifyError };
  },
  created() {
    this.notifyErrorCallback(this.notifyError);
  },
  methods: {
    ...mapMutations(USER_MODULE, [
      'notifyErrorCallback',
    ]),
  },
};
</script>
