export const PASSWORD_FEEDBACK_STATUS = {
  default: 'default',
  success: 'success',
  error: 'error',
};

export const PASSWORD_VALIDATION_TYPES = {
  lengthValidation: 'LENGTH_VALIDATION',
  uppercaseValidation: 'UPPERCASE_VALIDATION',
  lowercaseValidation: 'LOWERCASE_VALIDATION',
  numberValidation: 'NUMBER_VALIDATION',
  symbolValidation: 'SYMBOLS_VALIDATION',
};

export const PASSWORD_VALIDATION = [
  {
    validationKey: PASSWORD_VALIDATION_TYPES.lengthValidation,
    message: 'refactor.user_signup.step_1.password_requirements.password_length',
    validationPattern: new RegExp('^.{8,}$'),
  },
  {
    validationKey: PASSWORD_VALIDATION_TYPES.uppercaseValidation,
    message: 'refactor.user_signup.step_1.password_requirements.uppercase',
    validationPattern: new RegExp('^(?=.*[A-Z]).+$'),
  },
  {
    validationKey: PASSWORD_VALIDATION_TYPES.lowercaseValidation,
    message: 'refactor.user_signup.step_1.password_requirements.lowercase',
    validationPattern: new RegExp('^(?=.*[a-z]).+$'),
  },
  {
    validationKey: PASSWORD_VALIDATION_TYPES.numberValidation,
    message: 'refactor.user_signup.step_1.password_requirements.number',
    validationPattern: new RegExp('^(?=.*[0-9]).+$'),
  },
  {
    validationKey: PASSWORD_VALIDATION_TYPES.symbolValidation,
    message: 'refactor.user_signup.step_1.password_requirements.special_characters',
    validationPattern: new RegExp('[@#$%^&*_!]'),
  },
];
