<template>
  <div
    data-test-id="uploader-containter"
    class="StepDocumentUploader"
  >
    <div
      data-test-id="title-text"
      class="emobg-font-medium"
    >
      {{ title }}
    </div>
    <div data-test-id="subtitle-text">
      {{ subtitle }}
    </div>
    <div
      v-if="hasMultipleTypes"
      class="my-3"
    >
      <ui-radio
        v-for="type in config"
        :key="type.name"
        :value="currentType"
        :name="type.name"
        :option="type.name"
        :caption="type.name"
        :data-test-id="`type_${type.name}-radio_button`"
        class="mr-3"
        @changevalue="({ detail }) => currentType = detail"
      />
    </div>

    <div
      v-for="(input, index) in currentTypeInputs"
      :key="input.name"
      class="row flex-wrap mt-4"
    >
      <div class="col-sm-7 col-12">
        <p
          class="mb-1"
          v-html="`<b>${index + 1}</b> of <b>${currentTypeInputs.length}</b> <br/> ${input.title}`"
        />
      </div>
      <div class="col-sm-5 col-12 d-flex justify-content-end">
        <ui-file-drop
          :file-types.prop="[
            UPLOAD_FILE_TYPES.jpg,
            UPLOAD_FILE_TYPES.jpeg,
            UPLOAD_FILE_TYPES.png,
          ]"
          class="w-100 h-100"
          @filechanged="({ detail }) => callAction(input.action, detail)"
        >
          <div class="emobg-color-primary emobg-font-small mt-2">
            {{ $t('complete_documentation.manual_upload.upload_driving_license.upload') }}
          </div>
        </ui-file-drop>
      </div>
    </div>
  </div>
</template>
<script>
import head from 'lodash/head';
import get from 'lodash/get';
import find from 'lodash/find';
import reject from 'lodash/reject';
import forEach from 'lodash/forEach';
import size from 'lodash/size';

import { UPLOAD_FILE_TYPES } from '@emobg/web-utils';

export default {
  name: 'StepDocumentUploader',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    config: {
      type: Array,
      default: () => ([]),
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentType: null,
    };
  },
  computed: {
    hasMultipleTypes() {
      return size(this.config);
    },
    currentTypeInputs() {
      return get(find(this.config, { name: this.currentType }), 'inputs');
    },
  },
  created() {
    this.UPLOAD_FILE_TYPES = UPLOAD_FILE_TYPES;

    if (this.hasMultipleTypes) {
      this.currentType = get(head(this.config), 'name');
    }
  },
  methods: {
    get,
    callAction(action, value) {
      action(value);
      const currentTypeName = get(find(this.config, { name: this.currentType }), 'name');
      const otherTypes = reject(this.config, typeCollection => typeCollection.name === currentTypeName);
      this.resetTypes(otherTypes);
    },
    resetTypes(types) {
      forEach(types, typeCollection => forEach(typeCollection.inputs, input => input.action(null)));
    },
  },
};
</script>
