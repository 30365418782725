<script>
import map from 'lodash/map';
import config from '@/config';

export default {
  name: 'SubscriptionGroupsComponent',

  props: {
    useSubscriptions: {
      type: Boolean,
      default: false,
    },
    marketingNews: {
      type: Boolean,
      default: false,
    },
    subscriptions: {
      type: Array,
      default: () => [],
    },
  },

  created() {
    this.appName = config.data.appName;
  },

  methods: {
    onChangeSubscriptions({ uuid, isChecked }) {
      const subscriptions = map(this.subscriptions, subscription => ({
        ...subscription,
        checked: subscription.uuid === uuid ? isChecked : subscription.checked,
      }));

      this.$emit('update:subscriptions', subscriptions);
    },
  },
};
</script>

<template>
  <div>
    <label
      v-if="!useSubscriptions"
      class="d-flex mb-2"
    >
      <ui-checkbox
        :checked="marketingNews"
        data-test-id="user-signup_form_input-checkbox-receive-news"
        class="d-inline-block"
        @changevalue="({ detail }) => $emit('update:marketing-news', detail)"
      />
      <slot name="marketing-news">
        {{ $t('refactor.user_signup.step_4.legal_documents.receive_news', { operator: appName }) }}
      </slot>
    </label>
    <template v-else>
      <label
        v-for="(subscription, index) in subscriptions"
        :key="subscription.uuid"
        class="d-flex mb-3"
      >
        <ui-checkbox
          :checked="subscription.checked"
          :data-test-id="`user-signup_form_input-checkbox-subscription-${index}`"
          class="d-inline-block"
          @changevalue="({ detail }) => onChangeSubscriptions({ uuid: subscription.uuid, isChecked: detail })"
        />
        <span v-html="subscription.description" />
      </label>
    </template>
  </div>
</template>
