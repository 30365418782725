<template>
  <div
    :class="[
      'mb-6 PromoCode',
      isPromoCodeVisible ? 'PromoCode--active' : '',
      forceErrorOnWrongPromoCode && isPromoCodeVisible ? 'PromoCode--error' : '',
    ]"
  >
    <Transition name="fade-slide">
      <a
        v-if="!isPromoCodeVisible"
        href="javascript:void(0)"
        class="emobg-font-weight-bold pointer text-underline position-absolute"
        data-test-id="user-signup_form_button-promocode"
        @click="triggerPromoCodeStatus"
      >
        {{ $t('refactor.user_signup.step_4.fields.promo_code') }}
      </a>
    </Transition>
    <div class="d-flex">
      <Transition name="delayed-fade-slide">
        <ui-text-input
          v-if="isPromoCodeVisible"
          ref="promoCodeElement"
          v-validate.blurinput="{
            isPromoCodeWrong,
          }"
          :label="$t('refactor.user_signup.step_4.fields.promo_code_label')"
          :icon-left="ICONS.removeFilled"
          :value="promoCodeValue"
          name="promoCode"
          class="w-100 d-block"
          reverse
          data-test-id="user-signup_form_input-promocode"
          @changevalue="({ detail }) => promoCode(detail)"
          @clickicon="triggerPromoCodeStatus"
          @focusinput="resetInputValidation"
        />
      </Transition>
    </div>
  </div>
</template>
<script>
import get from 'lodash/get';
import { mapMutations, mapState } from 'vuex';
import { Validate, VALIDATION_EVENT_MODIFIERS } from '@emobg/vue-base';
import { USER_MODULE } from '../../constants/modules';
import { resetInputValidation } from '../../../utils/validation.util';

export default {
  name: 'PromoCodeComponent',
  directives: {
    Validate,
  },
  data() {
    return {
      isPromoCodeVisible: !!this.promoCodeValue,
      isValidated: false,
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      promoCodeValue: state => get(state, 'data.promoCode', ''),
      forceErrorOnWrongPromoCode: state => get(state, 'validation.forceErrorOnWrongPromoCode', false),
    }),
  },
  watch: {
    promoCodeValue() {
      this.setForceErrorOnWrongPromoCode(false);
      this.isValidated = false;
    },
    forceErrorOnWrongPromoCode() {
      const promoCodeElement = get(this, '$refs.promoCodeElement');

      if (promoCodeElement) {
        promoCodeElement.dispatchEvent(new Event(VALIDATION_EVENT_MODIFIERS.validate));
        this.isValidated = true;
      }
    },
  },
  methods: {
    resetInputValidation,
    ...mapMutations(USER_MODULE, ['promoCode', 'setForceErrorOnWrongPromoCode']),
    triggerPromoCodeStatus() {
      this.isPromoCodeVisible = !this.isPromoCodeVisible;

      if (!this.isPromoCodeVisible) {
        this.promoCode(null);
      }
    },
    isPromoCodeWrong() {
      return {
        isValid: !this.forceErrorOnWrongPromoCode,
        message: this.$t('refactor.user_signup.step_4.fields.promo_invalid'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.PromoCode {
  height: 24px;
  overflow: hidden;
  transition: height 0.2s ease 0.2s;

  &--active {
    height: 70px;
  }

  &--error {
    height: 92px;
  }
}
</style>
