import { useTranslations } from '@/composable/App/Translations';
import { COUNTRIES_ISO_CODES } from '@emobg/web-utils';

/**
 * Generates the document input configuration for the supplied country to be used in StepDocumentUploader
 * @param {String} country - Country to retrieve the configuration for
 * @param {function} idFrontAction - The action to call on input for the front of the id
 * @param {function} idBackAction - The action to call on input for the back of the id
 * @param {function} passportAction - The action to call on input for the passport
 *
 * @returns {object} - The input configuration for the fields of StepDocumentUploader
 */
export const getIdPassportConfig = (country, idFrontAction, idBackAction, passportAction) => {
  // TODO [ROAD-213]: Carsharing Matters into Phoenix FR Migration
  const { $t } = useTranslations();
  const configurations = {
    [COUNTRIES_ISO_CODES.italy]: [
      {
        name: $t('refactor.company_signup.fields.card_id'),
        inputs: [
          {
            name: 'extraDocuments.italianIdFront',
            title: $t('refactor.company_signup.extra_documents.front_id'),
            action: idFrontAction,
          },
          {
            name: 'extraDocuments.italianIdBack',
            title: $t('refactor.company_signup.extra_documents.back_id'),
            action: idBackAction,
          },
        ],
      },
      {
        name: $t('refactor.company_signup.fields.passport'),
        inputs: [
          {
            name: 'extraDocuments.italianPassport',
            title: $t('refactor.company_signup.extra_documents.passport'),
            action: passportAction,
          },
        ],
      },
    ],
  };

  return configurations[country];
};
