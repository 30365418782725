<template>
  <li
    :to="to"
    :class="[
      isActive ?
        `${colorClasses.background} cursor-pointer emobg-color-white`
        : `emobg-border-1 ${colorClasses.text} ${colorClasses.border} emobg-background-color-white`,
    ]"
    class="StepBullet emobg-font-small d-flex position-relative justify-content-center pill d-flex align-items-center justify-content-center"
    @click="go"
  >
    {{ number }}
  </li>
</template>
<script>
import { COLORS } from '@emobg/web-components';
import { navigationErrorHandler } from '@emobg/web-utils';
export default {
  props: {
    to: {
      required: true,
      validator: (value = {}) => value.path || value.name,
      type: Object,
    },
    done: {
      required: true,
      type: Number,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: COLORS.primary,
    },
    number: {
      required: true,
      type: [String, Number],
    },
  },
  computed: {
    isActive() {
      return this.done >= this.number;
    },
    colorClasses() {
      return {
        background: `emobg-background-color-${this.color}`,
        text: `emobg-color-${this.color}`,
        border: `emobg-border-color-${this.color}`,
      };
    },
  },
  methods: {
    go() {
      if (this.isActive || this.isClickable) {
        this.$router
          .push({ query: this.$route.query, ...(this.to || {}) })
          .catch(navigationErrorHandler);
      }
    },
  },
};
</script>
