import toLower from 'lodash/toLower';

export default {
  watch: {
    formData(value) {
      const name = toLower(this.$options.name);
      if (value.valid) {
        this.doneStep(name);
      } else {
        this.undoStep(name);
      }
    },
  },
};
