var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('div',{staticClass:"ExtraInformationComponent",attrs:{"data-test-id":"extra-information-component"}},[_c('ui-datetimepicker',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
      isRequired: {
        message: _vm.$t('refactor.company_signup.errors.mandatory'),
        whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
      },
      isValidDate: _vm.isValidDate,
    }),expression:"{\n      isRequired: {\n        message: $t('refactor.company_signup.errors.mandatory'),\n        whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n      },\n      isValidDate,\n    }",modifiers:{"blurinput":true}}],staticClass:"w-100 d-block mb-4",attrs:{"label":_vm.$t('refactor.user_signup.step_3.fields.birth_date'),"locale":_vm.locale,"data-test-id":"user-signup_form_input-birthdate","name":"birthDate","skiptime":"","external-validation":""},domProps:{"date":_vm.data.birthDate ? _vm.moment(_vm.data.birthDate) : null,"range":{
      start: _vm.minDate,
      end: _vm.maxDate,
    }},on:{"datechanged":({ detail }) => _vm.setBirthDate(detail),"focusinput":_vm.resetInputValidation}}),(_vm.isIdealPostCodeUsed)?_c('MuiInputLocation',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
      isRequired: {
        message: _vm.$t('refactor.user_signup.step_4.errors.location.required'),
        whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
      },
    }),expression:"{\n      isRequired: {\n        message: $t('refactor.user_signup.step_4.errors.location.required'),\n        whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n      },\n    }",modifiers:{"blurinput":true}}],ref:"ukLocation",staticClass:"w-100 d-block mb-4",attrs:{"id":"ukLocation","allowed-countries":[_vm.COUNTRIES_ISO_CODES.great_britain],"autocomplete-service":_vm.googleMapsAutocompleteService,"custom-predictions":[],"geocoder-service":_vm.googleMapsGeocoderService,"label":_vm.$t('refactor.user_signup.step_4.fields.address'),"placeholder":_vm.$t(_vm.addressHintKey),"value":_vm.displayAddress,"data-test-id":"user-signup_form_input-location-uk","name":"address"},on:{"change":_vm.changeLocationUk,"focusinput":_vm.resetInputValidation}}):_c('MuiInputLocation',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
      isRequired: {
        message: _vm.$t('refactor.user_signup.step_4.errors.location.required'),
        whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
      },
    }),expression:"{\n      isRequired: {\n        message: $t('refactor.user_signup.step_4.errors.location.required'),\n        whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n      },\n    }",modifiers:{"blurinput":true}}],staticClass:"w-100 d-block mb-4",attrs:{"allowed-countries":[],"autocomplete-service":_vm.googleMapsAutocompleteService,"custom-predictions":[],"geocoder-service":_vm.googleMapsGeocoderService,"label":_vm.$t('refactor.user_signup.step_4.fields.address'),"placeholder":_vm.$t(_vm.addressHintKey),"value":_vm.displayAddress,"data-test-id":"user-signup_form_input-location","name":"address","data-masked":""},on:{"change":_vm.address,"focusinput":_vm.resetInputValidation}}),_c('ui-text-input',{staticClass:"w-100 d-block mb-4",attrs:{"label":_vm.$t('refactor.user_signup.step_4.fields.additional_address'),"placeholder":_vm.$t(_vm.addressDetailsHintKey),"value":_vm.data.addressDetails,"name":"additional_address"},on:{"changevalue":({ detail }) => _vm.addressDetails(detail)}}),(_vm.isItalianUserComputed)?_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
      isRequired: {
        message: _vm.$t('refactor.user_signup.step_4.errors.italian_fiscal_code.required'),
        whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
      },
      isPattern: {
        pattern: _vm.ITALY_COMPANY_VALIDATION_PATTERN.userCodiceFiscale,
        message: _vm.$t('refactor.user_signup.step_4.errors.italian_fiscal_code.format'),
      },
    }),expression:"{\n      isRequired: {\n        message: $t('refactor.user_signup.step_4.errors.italian_fiscal_code.required'),\n        whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n      },\n      isPattern: {\n        pattern: ITALY_COMPANY_VALIDATION_PATTERN.userCodiceFiscale,\n        message: $t('refactor.user_signup.step_4.errors.italian_fiscal_code.format'),\n      },\n    }",modifiers:{"blurinput":true}}],ref:"italianFiscalCode",staticClass:"w-100 d-block",attrs:{"label":_vm.$t('refactor.user_signup.step_4.fields.fiscal_code_label'),"placeholder":_vm.$t('refactor.user_signup.step_4.fields.italian_fiscal_code_placeholder'),"value":_vm.italianFiscalCode,"data-test-id":"user-signup_form_input-italian-fiscal-code","name":"italian_fiscal_code"},on:{"changevalue":({ detail }) => _vm.changeItalianFiscalCode(detail),"focusinput":_vm.resetInputValidation}}):_vm._e(),(_vm.isItalianUserComputed)?_c('div',{staticClass:"emobg-font-small mb-4 pl-1 pt-1 emobg-color-ink-light"},[_vm._v(" "+_vm._s(_vm.$t('refactor.user_signup.step_4.fields.italian_fiscal_code_subtitle'))+" ")]):_vm._e(),_c('PhoneNumberWrapper',{staticClass:"w-100 d-block",attrs:{"country":_vm.countryCode,"label":_vm.$t('refactor.user_signup.step_4.fields.phone_number'),"on-blur":_vm.trackAddPhone,"value":_vm.data.phone,"data-test-id":"user-signup_form_input-phone","blur-validation":"","name":"phone"},on:{"change":_vm.phone,"isValid":_vm.setIsPhoneNumberValid}}),_c('div',{staticClass:"emobg-font-small mb-5 pl-1 pt-1 emobg-color-ink-light"},[_vm._v(" "+_vm._s(_vm.$t('refactor.user_signup.step_3.mobile'))+" ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }