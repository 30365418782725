<template>
  <div class="PasswordFeedBackComponent">
    <div class="PasswordFeedBack__wrapper emobg-body-s-regular mt-3">
      <p class="emobg-color-ink-light">
        {{ $t('refactor.user_signup.step_1.password_requirements.label') }}
      </p>
      <div
        v-for="requirement in requirements"
        :key="requirement.validationKey"
        class="PasswordFeedBack__item d-flex align-items-center py-1"
      >
        <div class="PasswordFeedBack__item__status mr-2">
          <div
            v-if="requirement.status === PASSWORD_FEEDBACK_STATUS.default"
            class="PasswordFeedBack__status-default"
          />
          <ui-icon
            v-if="requirement.status === PASSWORD_FEEDBACK_STATUS.error"
            :icon="ICONS.bold.clear_result"
            :color="COLORS.danger"
            :size="ICONS_SIZES.xSmall"
          />
          <ui-icon
            v-if="requirement.status === PASSWORD_FEEDBACK_STATUS.success"
            :icon="ICONS.bold.check"
            :color="COLORS.success"
            :size="ICONS_SIZES.xSmall"
          />
        </div>
        <div :class="messageStyle[requirement.status]">
          {{ $t(requirement.message) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {
  computed, onMounted, ref, watch,
} from 'vue';
import {
  eachItem, isNullValue, mapArray, SPEED,
} from '@emobg/web-utils';
import debounce from 'lodash/debounce';
import { PASSWORD_FEEDBACK_STATUS, PASSWORD_VALIDATION_TYPES } from '../constants/PasswordFeedBackValidation.const';

export default {
  name: 'PasswordFeedBackComponent',
  props: {
    passwordRequirements: {
      type: Array,
      default: () => [],
    },
    password: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const requirements = ref(mapArray(props.passwordRequirements, requirement => ({
      ...requirement,
      status: PASSWORD_FEEDBACK_STATUS.default,
    })));
    const alphaNumeric = new RegExp(/[^a-zA-Z0-9]/g);
    const messageStyle = computed(() => ({
      [PASSWORD_FEEDBACK_STATUS.success]: 'emobg-color-ink-light',
      [PASSWORD_FEEDBACK_STATUS.default]: 'emobg-color-ink-lightest',
      [PASSWORD_FEEDBACK_STATUS.error]: 'emobg-color-danger',
    }));
    function checkRequirements(localPassword, requirement, index) {
      if (requirement.validationKey !== PASSWORD_VALIDATION_TYPES.symbolValidation) {
        const isRequirementValid = requirement.validationPattern.exec(localPassword);
        requirements.value[index].status = isRequirementValid ? PASSWORD_FEEDBACK_STATUS.success : PASSWORD_FEEDBACK_STATUS.default;
      } else {
        const symbols = localPassword.match(alphaNumeric);
        const hasSymbols = !isNullValue(symbols);
        let isSymbolValid = true;
        if (hasSymbols) {
          eachItem(symbols, symbol => {
            if (isSymbolValid) {
              isSymbolValid = requirement.validationPattern.test(symbol);
            }
          });
          requirements.value[index].status = isSymbolValid ? PASSWORD_FEEDBACK_STATUS.success : PASSWORD_FEEDBACK_STATUS.error;
        } else {
          requirements.value[index].status = PASSWORD_FEEDBACK_STATUS.default;
        }
      }
    }
    function checkPasswordValidation() {
      let areAllRequirementsValid = true;
      eachItem(requirements.value, requirement => {
        if (requirement.status !== PASSWORD_FEEDBACK_STATUS.success) {
          areAllRequirementsValid = false;
        }
      });
      return areAllRequirementsValid;
    }
    function validatePassword(localPassword) {
      eachItem(requirements.value, (requirement, index) => {
        if (localPassword) {
          checkRequirements(localPassword, requirement, index);
        } else {
          requirements.value[index].status = PASSWORD_FEEDBACK_STATUS.default;
        }
      });
      emit('onPasswordChange', checkPasswordValidation());
    }
    const delayedValidation = debounce(validatePassword, SPEED.fast);
    onMounted(() => validatePassword(props.password));
    watch(() => props.password, (newValue) => {
      delayedValidation(newValue);
    });
    return { requirements, PASSWORD_FEEDBACK_STATUS, messageStyle };
  },
};
</script>

<style lang="scss">
@import "~@emobg/sass/colors/variables";

.PasswordFeedBack__status-default {
  width: 16px;
  height: 16px;
  background-color: map-get($motion-grayscale, "ground-light");
  border-radius: 100%;
}
</style>
