export const SIGNUP_AGE = {
  min: 19,
  max: 80,
};

export const STEP_1_REFS = {
  companyName: 'companyName',
  country: 'country',
  legalForm: 'legalForm',
};

export const STEP_2_REFS = {
  companyEmail: 'companyEmail',
  password: 'password',
  title: 'title',
  firstName: 'firstName',
  lastName: 'lastName',
  birthDate: 'birthDate',
};

export const MB_IN_BYTES = 1048576;
