<template>
  <SignUpTemplate :left-image="linkAccount.isLinkAccount ? userSignUpImage : businessSignUpImage">
    <div class="w-100 pt-lg-6 pt-0">
      <ul :class="`navigator navigator--${flavor} pb-4`">
        <div :class="`navigator__wrapper navigator__wrapper--${flavor}`">
          <SignUpBullet
            :to="{ name: COMPANY_SIGN_UP_ROUTES.step1 }"
            :done="getStepNumber"
            :form="done"
            :number="1"
            :color="stepperColor"
            class="mr-3"
          />
          <SignUpBullet
            :to="{ name: COMPANY_SIGN_UP_ROUTES.step2 }"
            :done="getStepNumber"
            :form="done"
            :number="2"
            :color="stepperColor"
            class="mr-3"
          />
          <SignUpBullet
            :to="{ name: COMPANY_SIGN_UP_ROUTES.step3 }"
            :done="getStepNumber"
            :form="done"
            :number="3"
            :color="stepperColor"
          />
        </div>
      </ul>
      <Transition
        :name="transitionClass"
        @before-enter="() => lastStep(getStepNumber)"
      >
        <RouterView
          class="content"
          :auth="auth"
        />
      </Transition>
    </div>
  </SignUpTemplate>
</template>
<script>

import { mapMutations, mapState } from 'vuex';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { webAppColors } from '@/constants/themes';
import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, PICTURES_KEYNAMES } from '@/constants/assetsRepository';
import COMPANY_SIGN_UP_ROUTES from '../router/routes-names';

import numberOfEmployees from './constants/NumberOfEmployees';
import SignUpTemplate from '../Templates/SignUpTemplate';
import SignUpBullet from '../../components/SignUpBullet';

import { COMPANY_MODULE, GENERIC_MODULE } from '../constants/modules';

export default {
  name: 'Company',
  components: {
    SignUpBullet,
    SignUpTemplate,
  },
  props: {
    auth: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { notifyError } = useNotifications();
    const stepperColor = webAppColors.brand;
    return { notifyError, stepperColor };
  },
  computed: {
    ...mapState(COMPANY_MODULE, {
      currentStep: state => state.currentStep,
      done: state => state.done,
      linkAccount: state => state.linkAccount,
      lastStepNumber: state => state.lastStep,
    }),
    getStepNumber() {
      const route = this.$route.name.match(/\d+/);
      return route ? parseInt(route[0], 10) : 1;
    },
    transitionClass() {
      return this.lastStepNumber > this.getStepNumber ? 'slide-fade-fallback' : 'slide-fade';
    },
    userSignUpImage: () => getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.SIGNUP.b2c),
    businessSignUpImage: () => getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.SIGNUP.b2b),
  },
  created() {
    this.COMPANY_SIGN_UP_ROUTES = COMPANY_SIGN_UP_ROUTES;
    if (this.$route.query.test === 'true') {
      localStorage.setItem('env_test', true);
    }
    this.setCompanySize();
  },
  mounted() {
    this.notifyErrorCallback(this.notifyError);
  },
  methods: {
    ...mapMutations(COMPANY_MODULE, [
      'updateStep',
      'notifyErrorCallback',
      'linkAccountUser',
      'isLinkAccount',
      'companySizes',
      'idpUuid',
      'lastStep',
    ]),
    ...mapMutations(GENERIC_MODULE, [
      'locale',
    ]),
    setCompanySize() {
      this.companySizes(numberOfEmployees.map(item => {
        item.label = this.$t(item.label);
        return item;
      }));
    },
  },
};
</script>
