<template>
  <RouterView :auth="auth" />
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import SegmentSnippet from '@/assets/js/segmentSnippet';
import languageService from '@/services/Language';
import { COMPANY_MODULE, GENERIC_MODULE, USER_MODULE } from './constants/modules';

export default {
  name: 'SignUp',
  props: {
    auth: {
      type: Object,
      required: true,
    },
  },
  created() {
    // Checks if it is Internal_testing
    this.internalTesting(this.$route.query.internal_testing);

    // Load Generic data to Store
    this.setLocales();
    this.getCountriesByService();
    this.getUbeeqoTitles();
    this.checkIfResetStore();
  },
  async mounted() {
    const analytics = await SegmentSnippet.getInstance();
    if (analytics) {
      const anonymousId = analytics.user().anonymousId();
      this.trackingId(anonymousId);
      analytics.identify(anonymousId, {
        anonymousId,
      });
    }
  },
  methods: {
    ...mapActions(GENERIC_MODULE, [
      'getCountriesByService',
      'getUbeeqoTitles',
    ]),
    ...mapMutations(COMPANY_MODULE, ['resetCompany']),
    ...mapMutations(GENERIC_MODULE, ['locale', 'internalTesting']),
    ...mapMutations(USER_MODULE, ['resetUser', 'trackingId']),
    checkIfResetStore() {
      const lastEnter = localStorage.getItem('last-entered');
      const currentDate = moment().format();
      const resetStorage = moment(currentDate).diff(moment(lastEnter), 'minutes') >= 5; // 5 minutes
      if (!lastEnter || !resetStorage) {
        localStorage.setItem('last-entered', currentDate);
      }

      if (resetStorage) {
        this.resetCompany();
        this.resetUser();
        localStorage.setItem('last-entered', currentDate);
      }
    },
    setLocales() {
      const locale = languageService.getLanguage();
      this.locale(locale);
    },
  },
};
</script>
