<script>
import map from 'lodash/map';
import get from 'lodash/get';

import { groupItemsBy, isAccepted } from '@/helpers/legalDocuments/legalDocumentsHelpers';
import { compactArray, isArrayValue } from '@emobg/web-utils';

export default {
  name: 'LegalDocumentsComponents',

  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    accepted: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    groupItemsBy,
    isAccepted,

    /**
     * Returns a sentence with a link for each legal document in the group
     * @param group
     * @return {string}
     */
    makeLinks(group) {
      const groupedDocuments = isArrayValue(group) ? group : [];

      const links = map(groupedDocuments, document => `
          <a
            class="emobg-link"
            href="${document.url}"
            target="_blank"
          >${document.document_name}</a>
        `);

      const legalDocuments = links.join(', ');
      return this.$t('refactor.user_signup.step_4.legal_documents.accept_it', { legalDocuments });
    },
    onChange(value, group) {
      const groupedlDocuments = isArrayValue(group) ? group : [];

      const uuids = compactArray(map(groupedlDocuments, 'uuid'));

      this.$emit(value ? 'accept' : 'reject', { value: uuids });
    },
    groupName(group) {
      return get(group, '0.group');
    },
  },
};
</script>

<template>
  <div class="LegalDocuments">
    <label
      v-for="(group, index) in groupItemsBy(documents)"
      :key="`group-${index}`"
      class="d-flex mb-3"
    >
      <ui-checkbox
        :checked="isAccepted(accepted, group)"
        :data-test-id="`user-signup_form_input-checkbox-document-${groupName(group) + 1}`"
        class="d-inline-block"
        @changevalue="({ detail }) => onChange(detail, group)"
      />
      <span v-html="makeLinks(group)" />
    </label>
  </div>
</template>
